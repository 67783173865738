<template>
    <div class="page-news-detail g-page">
        <div class="wp">
            <el-breadcrumb separator-class="el-icon-arrow-right">
                <el-breadcrumb-item :to="{ path:'/'}">首页</el-breadcrumb-item>
                <el-breadcrumb-item :to="{path:'/news'}">新闻中心</el-breadcrumb-item>
                <el-breadcrumb-item>详情</el-breadcrumb-item>
            </el-breadcrumb>
            <div class="news-detail" v-if="newInfo">
                <div class="title">
                    <h1>{{ newInfo.title }}</h1>
                    <p>{{ newInfo.updateTime }}</p>
                </div>
                <div class="content">
                    <p class="" v-html="`${newInfo.contentText}`"></p>
                </div>
                <div class="page-switch">
                    <p class="cursorPointer" @click="newInfo.newsPre.id?getarticleNewsDetail(newInfo.newsPre.id):''">
                        上一篇: {{newInfo.newsPre&&newInfo.newsPre.title?newInfo.newsPre.title:'已经是第一篇了'}}
                    </p>
                    <p class="cursorPointer" @click="newInfo.newsNext.id?getarticleNewsDetail(newInfo.newsNext.id):''">
                        下一篇: {{newInfo.newsNext&&newInfo.newsNext.title?newInfo.newsNext.title:'已经是最后一篇了'}}
                    </p>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import {articleNewsDetail} from "@/api/home";

export default {
    name: "NewsDetail",
    data() {
        return {
            newInfo:'',

        }
    },
    mounted() {
        console.log(this.$route.query) // 获取传递参数
        this.getarticleNewsDetail(this.$route.query.id)
    },
    methods:{

        // 新闻详情
        getarticleNewsDetail(id){
            articleNewsDetail({id:id,isPreNext:1}).then((res) => {
                if (res.data.code==200) {
                    this.newInfo=res.data.data
                    this.newInfo.contentText=this.$utils.showHtml(this.newInfo.contentText)
                    document.documentElement.scrollTop = 0;  // 回到顶部
                }

            })
        },
    },
}
</script>

<style scoped>

</style>
